<template>
    <div class="container">
        <div class="main">
            <div class="left">
                <p class="title">客户管理，拉近你与客户的距离</p>
                <div class="content">
                    <div class="row"><div class="icon">1</div><p style="width:548px;">持仓监控，帮助客户监控持仓绩效与风险：设定止盈、止损、持有时长等目标，筛选需要调仓的客户和基金，及时提醒客户调仓</p></div>
                    <div class="row"><div class="icon">2</div><p style="width:548px;">持仓分析，让你更懂客户：通过分析客户持仓，全面了解客户收益、风险、资产配置等详情，没人比你更懂客户</p></div>
                    <div class="row"><div class="icon">3</div><p style="width:548px;">持仓报告，让你沟通时更加专业：一键下载客户持仓分析报告PDF，让你和客户沟通时有理有据，有情有义</p></div>
                </div>
                <el-button round size="mini" @click="handleClick">免费试用</el-button>
            </div>
            <div class="right">
                <img src="~@/assets/invest/section2.png" alt="">
            </div>
        </div>
        <div class="right-bg"><img src="~@/assets/invest/section2_bg.png" alt=""></div>
    </div>
</template>

<script>
export default {
    methods: {
        handleClick() {
            this.$store.commit('sign/setVisible', true)
        }
    }
}
</script>

<style scoped>
.container{
    position: relative;
    height: 714px;
    min-width: 1340px;
    background: #fafafa;
}
.left{
    font-family: 'pinfang_xiti', sans-serif;
    width: 568px;
    /* margin-left: 348px; */
    padding-left: 57px;
    margin-top: 196px;
}
.main{
    position: relative;
    max-width: 1560px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    /* align-items: center; */
}
.right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: left 50% / 150% 100% no-repeat url('~@/assets/invest/section2_bg.png'); */
}
.right-bg{
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 714px;
    z-index: 2;
}
.right-bg img{
    height: 714px;
}
.right img{
    width: 850px;
}
.title{
    width: 504px;
    height: 50px;
    font-family: PingFangSC-Medium, PingFang SC;;
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    white-space: nowrap;
    text-indent: 24px;
    line-height: 50px;
}
.icon{
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #CCCCCC;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    margin-right: 10px;
    margin-top: 5px;
}
.row{
    display: flex;
    align-items: flex-start;
    text-align: left;
    color: #666666;
    font-size: 18px;
    margin-top: 20px;
    /* line-height: 14px; */
}
.el-button{
    margin-left: -390px;
    margin-top: 20px;
    font-size: 18px;
    border: 1px solid #333333;
    color: #333333;
    padding: 11px 37px;
}
</style>