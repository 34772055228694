<template>
    <div class="container">
        <div class="wrap">
            <p class="title">用数据建立信任 让结果帮你说话</p>
            <p class="content">智策基金投顾平台，是帮助投资顾问和理财师，<br>提升客户服务效率和投研能力的智能平台。</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    margin-top: 80px;
    height: 488px;
    background: center url('~@/assets/invest/header.png');
}
.wrap{
    margin: 0 auto;
    margin-top: 114px;
    padding-left: 500px;
    width: 636px;
}
.title{
    font-family: 'zcool_gaoduanhei', fantasy;
    font-size: 48px;
    color: #FFFFFF;
    text-align: left;
    letter-spacing: -4px;
}
.content{
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 24px;
    color: #FFFFFF;
    text-align: left;
}
</style>